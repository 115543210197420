import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import { useQuery } from "@apollo/react-hooks"
import gql from "graphql-tag"
import vibeImage from "../images/vibe.png"
import InstaLogo from "../images/insta.png"

export default function Inno() {
  return (
    <PageWrapper>
      <Wrapper>
        <BackgroundImage>
          <TitleBox>
            <Title>VIBE</Title>
            <SubTitle>fest med dine venner👀</SubTitle>
          </TitleBox>
          <ButtonBox>
            <DescText>lancerer på inno fredag🍾</DescText>
            <DescText>
              deltag i pre-launch konkurrence om moet, hennesy, øl og penge👇
            </DescText>

            <a
              style={{ textDecorationLine: "none" }}
              href="https://m.me/joinvibe?ref=konkurrence"
            >
              <Button>
                <ButtonText>deltag nu</ButtonText>
              </Button>
            </a>
          </ButtonBox>
        </BackgroundImage>
        <AmbassadorBox>
          <AmbassadorTitle>bliv verified📣</AmbassadorTitle>
          <AmbassadorButtonBox>
            <a
              style={{ textDecorationLine: "none" }}
              href="https://form.typeform.com/to/iWHk8M6Z"
            >
              <OtherButton>
                <ButtonText>ansøg nu</ButtonText>
              </OtherButton>
            </a>
          </AmbassadorButtonBox>
        </AmbassadorBox>
        <InfoBox>
          <InfoText>
            VIBE er ikke associeret med Niels Brock Inno eller andre gymnasier
          </InfoText>
        </InfoBox>
      </Wrapper>
    </PageWrapper>
  )
}

// Page Wrapper
const PageWrapper = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: #121212;
`

// Content wrapper
const Wrapper = styled.div`
  height: 100%;
  width: 100%;

  align-self: center;
  background-color: #121212;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
`

const BackgroundImage = styled.div`
  background-image: url(${vibeImage});
  background-color: #121212;
  width: 100vw;
  max-width: 600px;
  min-height: 600px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  align-self: center;

  display: flex;
  justify-content: center;
  aling-items: center;
  flex-direction: column;
  margin-bottom: 15px;
`
const TitleBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`

const Title = styled.p`
  font-family: Gotham;
  font-size: 55px;
  letter-spacing: 2px;
  text-shadow: 0px 2px 0px rgba(0, 0, 0, 0.2);
  color: #fdde68;
`
const SubTitle = styled.p`
  font-family: Arial, Helvetica Neue, Helvetica, sans-serif;
  font-weight: 600;
  color: #fff;
  margin-top: 5px;
  font-size: 24px;
  letter-spacing: 1px;
  line-height: 28px;
  width: 200px;
  text-align: center;
  text-shadow: 0px 2px 0px rgba(0, 0, 0, 0.2);
`
const ButtonBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 15px;
  flex-direction: column;
`

const DescText = styled.p`
  font-family: Arial, Helvetica Neue, Helvetica, sans-serif;
  font-weight: 600;
  color: #fff;
  font-size: 20px;
  width: 250px;
  margin-bottom: 15px;
  text-align: center;
  text-shadow: 0px 2px 0px rgba(0, 0, 0, 0.2);
`

const OtherButton = styled.div`
  width: 190px;
  height: 55px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 22px;
  background-color: #fdde68;
  flex-direction: row;
`

const Button = styled.div`
  width: 180px;
  height: 55px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 22px;
  background-color: #fdde68;
  flex-direction: row;
  margin-top: 25px;
`

const ButtonText = styled.p`
  text-align: center;
  vertical-align: middle;
  margin: 0;
  margin-bottom: 1px;
  text-decoration: none;
  color: #333;

  font-family: Arial, Helvetica Neue, Helvetica, sans-serif !important;
  font-weight: 900;
  font-size: 20px;
  line-height: 55px;
`
const AmbassadorBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 35px;
  align-self: center;
  background-color: #121212;
`
const AmbassadorTitle = styled.p`
  font-family: Arial, Helvetica Neue, Helvetica, sans-serif;
  font-weight: 600;
  color: #fff;
  margin-top: 5px;
  font-size: 24px;
  letter-spacing: 1px;
  line-height: 24px;
  text-align: center;
  text-shadow: 0px 2px 0px rgba(0, 0, 0, 0.2);
`

const AmbassadorDesc = styled.p`
  font-family: Arial, Helvetica Neue, Helvetica, sans-serif;
  font-weight: 600;
  color: #fff;
  font-size: 20px;
  width: 250px;
  margin-bottom: 8px;
  text-align: center;
  text-shadow: 0px 2px 0px rgba(0, 0, 0, 0.2);
  margin: 0;
`

const AmbassadorButtonBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`

const InstaBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  align-self: center;
  background-color: #121212;
  width: 100%;
  margin-top: 0px;
`
const InstaImage = styled.img`
  content: url(${InstaLogo});
  flex: 1;
  width: 70px;
  height: 70px;
  object-fit: contain;
`

const InfoBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  align-self: center;
  background-color: #121212;
  width: 100%;
  margin-top: 0px;
`

const InfoText = styled.p`
  font-family: Arial, Helvetica Neue, Helvetica, sans-serif;
  font-weight: 400;
  color: #fff;
  font-size: 12px;
  line-height: 14px;
  width: 230px;
  margin-bottom: 8px;
  text-align: center;
  margin: 0;
  margin-bottom: 10px;
`
